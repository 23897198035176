;!function(){try{var e="undefined"!=typeof window?window:"undefined"!=typeof global?global:"undefined"!=typeof self?self:{},n=(new Error).stack;n&&(e._sentryDebugIds=e._sentryDebugIds||{},e._sentryDebugIds[n]="784fdf94-e1da-4176-801d-e891fdfec47b",e._sentryDebugIdIdentifier="sentry-dbid-784fdf94-e1da-4176-801d-e891fdfec47b")}catch(e){}}();
{
  var _sentryModuleMetadataGlobal =
    typeof window !== "undefined"
      ? window
      : typeof global !== "undefined"
      ? global
      : typeof self !== "undefined"
      ? self
      : {};

  _sentryModuleMetadataGlobal._sentryModuleMetadata =
    _sentryModuleMetadataGlobal._sentryModuleMetadata || {};

  _sentryModuleMetadataGlobal._sentryModuleMetadata[new Error().stack] =
    Object.assign(
      {},
      _sentryModuleMetadataGlobal._sentryModuleMetadata[new Error().stack],
      {"_sentryBundlerPluginAppKey:loveholidays-sunrise":true}
    );
}
"use strict";
(self["__LOADABLE_LOADED_CHUNKS__"] = self["__LOADABLE_LOADED_CHUNKS__"] || []).push([[6771],{

/***/ 26771:
/***/ ((module) => {

module.exports = JSON.parse('{"starRating":"Sterne-Bewertung: {{rating}}","brandReviewRating":"{{brand}} Kundenbewertung: {{rating}}","holidayCheckRating":"{{rating}}","basedOnXReviews":"Basierend auf einer Bewertung","basedOnXReviews_plural":"Basierend auf {{count}} Bewertungen","xReviews":"Eine Bewertung","xReviews_plural":"{{count}} Bewertungen","xPhotos":"+ {{count}} Foto","xPhotos_plural":"+ {{count}} Fotos","loading":"Wird geladen","increment":"Mehr","decrement":"Weniger","previous":"Vorherige","next":"Nächste","close":"Schließen","back":"Zurück","moreInformation":"Mehr Informationen","password":"Passwort","ratedXOutOf5BasedOnXReviewsOnX":"Bewertet <1> / 5 basierend auf <2>einer Bewertung</2> auf <3>","ratedXOutOf5BasedOnXReviewsOnX_plural":"Bewertet <1> / 5 basierend auf <2>{{count}} Bewertungen</2> auf <3>","showingRangeOfReview":"Hier werden unsere {{range}}-Sterne-Bewertungen angezeigt","imageXOutOfX":"Bild {{current}} von {{total}}","hotelImageComingSoon":"Hotelfoto kommt bald."}');

/***/ })

}]);